[data-animation='glitch-squiggly']{
    -webkit-animation-name: glitch-squiggly__animation;
    animation-name: glitch-squiggly__animation;

    -webkit-animation-duration: var(--duration);
    animation-duration: var(--duration);

    -webkit-animation-timing-function: cubic-bezier(.57,.21,.69,3.25);
    animation-timing-function: cubic-bezier(.57,.21,.69,3.25);

    -webkit-animation-iteration-count: var(--iteration-count);
    animation-iteration-count: var(--iteration-count);
}

.glitch-squiggly__svg-filters {
    height: 0;
    width: 0;
}

@-webkit-keyframes glitch-squiggly__animation {
    0% {
        -webkit-filter: var(--squiggly__filter--id-0);
        filter: var(--squiggly__filter--id-0);
    }
    25% {
        -webkit-filter: var(--squiggly__filter--id-1);
        filter: var(--squiggly__filter--id-1);
    }
    50% {
        -webkit-filter: var(--squiggly__filter--id-2);
        filter: var(--squiggly__filter--id-2);
    }
    75% {
        -webkit-filter: var(--squiggly__filter--id-3);
        filter: var(--squiggly__filter--id-3);
    }
    100% {
        -webkit-filter: var(--squiggly__filter--id-4);
        filter: var(--squiggly__filter--id-4);
    }
}

@keyframes glitch-squiggly__animation {
    0% {
        -webkit-filter: var(--squiggly__filter--id-0);
        filter: var(--squiggly__filter--id-0);
    }
    25% {
        -webkit-filter: var(--squiggly__filter--id-1);
        filter: var(--squiggly__filter--id-1);
    }
    50% {
        -webkit-filter: var(--squiggly__filter--id-2);
        filter: var(--squiggly__filter--id-2);
    }
    75% {
        -webkit-filter: var(--squiggly__filter--id-3);
        filter: var(--squiggly__filter--id-3);
    }
    100% {
        -webkit-filter: var(--squiggly__filter--id-4);
        filter: var(--squiggly__filter--id-4);
    }
}
